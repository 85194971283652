import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import backend from '../../../lib/backend';
import documentsSubmitForm from '../../../state/forms/eslForms/documentsSubmitForm';
import { ApplicationAttachment } from '../../../types/applicationAttachment';
import { ValidationError } from '../../../types/errors/validation';
import {
  SponsorshipApplicationFiles,
  StudentSponsorshipApplication,
} from '../../../types/sponsorshipApplication';

/**
 * Interface for the return type of the useDocumentsForm hook
 */
interface UseDocumentsForm {
  submitForm: () => Promise<SponsorshipApplicationFiles>;
  shouldSubmitDocuments: () => boolean;
}

const MAX_FILE_SIZE = 200 * 1024 * 1024; // 200MB in bytes

/**
 * Custom hook to manage the state and submission logic for the documents form.
 *
 * @param sponsorshipApplication - The sponsorship application data
 * @param requiredDocuments - Array of required document types
 * @returns An object containing the submitForm handler function
 */
const useDocumentsForm = (
  sponsorshipApplication: StudentSponsorshipApplication | null,
  requiredDocuments: ApplicationAttachment[] | null,
): UseDocumentsForm => {
  const documentFiles = useRecoilValue(documentsSubmitForm.fields.documentFiles.valueState);

  const validateForm = useCallback(() => {
    if (requiredDocuments && requiredDocuments.length > 0) {
      for (const doc of requiredDocuments) {
        const file = documentFiles[doc.document_type.type];

        if (!file) {
          throw new ValidationError('Please upload all required files before submitting.');
        }
      }
    }

    for (const key in documentFiles) {
      const file = documentFiles[key];

      if (file instanceof File && file.size > MAX_FILE_SIZE) {
        throw new ValidationError('File is larger than maximum file size.');
      }
    }
  }, [ documentFiles, requiredDocuments ]);

  const shouldSubmitDocuments = useCallback((): boolean => {
    // TODO: SSP-3188: Improve handling of updates to previously submitted documents
    const noUploadedFiles = !sponsorshipApplication?.uploaded_files || Object.keys(sponsorshipApplication.uploaded_files).length === 0;
    const noDocumentFiles = !documentFiles || Object.keys(documentFiles).length === 0;

    if (noUploadedFiles) return true; // If we don't have uploaded_files then we should validate for required documents
    if (noDocumentFiles) return true; // If we don't have any document in memory, we should validate required fields.

    const uploadedFileNames = Object.values(sponsorshipApplication.uploaded_files).map((file) => file?.name);

    for (const key in documentFiles) {
      const file = documentFiles[key];

      if (!file?.name) return true;

      if (!uploadedFileNames.includes(file.name)) {
        return true;
      }
    }

    return false;
  }, [ documentFiles, sponsorshipApplication?.uploaded_files ]);

  const submitForm = useCallback(async () => {
    validateForm();

    const formData = new FormData();

    Object.keys(documentFiles).forEach((key) => {
      const file = documentFiles[key];

      if (file instanceof File) {
        console.log(`Uploading file: ${file.name}, Size: ${file.size} bytes`);
        formData.append(key, file);
      }
    });

    try {
      const { data: sponsorshipApplicationFiles } = await backend.submitFiles(sponsorshipApplication?.id as string, formData);
      console.log('File upload successful:', sponsorshipApplicationFiles);

      return sponsorshipApplicationFiles;
    } catch (error) {
      console.error('File upload failed:', error);

      throw error;
    }
  }, [ validateForm, documentFiles, sponsorshipApplication ]);

  return { submitForm, shouldSubmitDocuments };
};

export default useDocumentsForm;
